












































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import AnchoredHeading from '@/features/sr-requirement-search/AnchoredHeading.vue';
import RequirementItem from '@/components/common/RequirementItem.vue';
import QmsRequirementCurrent from '@/components/common/QmsRequirementCurrent.vue';
import { Requirement, RequirementMap } from '@/features/sr-model/Requirement';
import ImpactsMixin from '@/features/impacts/components/ImpactsMixin.vue';
import { mapMutations, mapState } from 'vuex';
import { RootState } from '@/store';
import { ImpactsMutations } from '@/features/impacts/store';
import ImpactQmsRequirementEdit from '@/features/impacts/components/ImpactQmsRequirementEdit.vue';
import { SopSearchPart } from '@/model';
import { TemplateContent } from '@/features/template-content/template-content-model';
import ImpactQmsRequirementProposal from '@/features/impacts/components/ImpactQmsRequirementProposal.vue';
import ImpactQmsRequirementApplicability from '@/features/impacts/components/ImpactQmsRequirementApplicability.vue';

@Component({
  components: { ImpactQmsRequirementApplicability, ImpactQmsRequirementProposal, ImpactQmsRequirementEdit, QmsRequirementCurrent, RequirementItem, AnchoredHeading },
  computed: {
    ...mapState<RootState>({
      isEditingRequirement(this: ImpactEntryToRequirement, state: RootState) {
        return this.requirement.id === state.impacts.editingRequirementId;
      },
      editQmsRequirements: (state: RootState) => state.impacts.editQmsRequirements,
      isImpactMode: (state: RootState) => state.impacts.isImpactMode,
      toRegulationRequirements: (state: RootState) => state.impacts.toRegulationRequirements,
    })
  },
  methods: {
    ...mapMutations({
      editRequirement: ImpactsMutations.EDITING_REQUIREMENT_ID,
    })
  }
})
export default class ImpactEntryToRequirement extends mixins<ImpactsMixin>(ImpactsMixin) {

  @Prop()
  requirement: Requirement;
  @Prop({ default: () => [] })
  proposedSops: SopSearchPart[];
  @Prop({ default: () => [] })
  proposedTemplateContents: TemplateContent[];

  toRegulationRequirements: RequirementMap;

  get qmsRequirement() {
    return this.toQmsRequirementByRequirementId(this.requirement.id)
  }


  get interpretedInRequirementInfo(): string {
    if (!this.qmsRequirement.interpretedInRequirementId) {
      throw new Error('QmsRequirement has no expected interpretedInRequirementId');
    }
    const requirement = this.toRegulationRequirements[this.qmsRequirement.interpretedInRequirementId];
    const infoText = ((requirement.paragraph ?? '') + ' ' + requirement.text).trim();
    return infoText.substr(0, Math.min(infoText.length, 50)) + (infoText.length > 50 ? '...' : '');
  }
}
